import request from '@/utils/request';
import { HomeData } from './Data';
import { IResponse } from '@/services/data';

// 首页
export async function fetchHomeList(params: any) {
  return request('/api/rolldetail', {
    method: 'GET',
    params: {
      ...params
    }
  })
}

export async function fetchBindGrade(params: { grade_id: string }) {
  return request('/api/grade', {
    method: 'POST',
    data: {
      ...params
    }
  })
}

export async function fetchWXConfig(params: { config_url : string }) {
  return request('/api/share', {
    method: 'POST',
    data: {
      ...params
    }
  })
}

export async function fetchConfirmShare() {
  return request('/api/user/share', {
    method: 'GET',
  })
}
export async function fetchLookLession() {
  return request('/user/lession', {
    method: 'GET',
  })
}